.div-container{
  display: flex;
	flex-direction: column;
	justify-content: center;
  align-items: center;

  .loading-text{
    // background-color: rgb(255, 0, 0);
    color: black;
    font-size: 16px;
    // padding: 10px;
    // border-radius: 4px;
    // margin-top: 17rem;
    margin-top: 13rem;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .loading-progress {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loading-progress div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: rgb(183, 149, 133);
    animation: loading-progress 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .loading-progress div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .loading-progress div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .loading-progress div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes loading-progress {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  
  
}
